import React from "react";
import './Footer.css'

function Footer() {
  const year = new Date().getFullYear();
  return (
    <>
      <footer>
        <div className="footer">
          <div className="footer-top">
            <div className="container-xl">
              <div className="row">
                <div className="col-12">
                  <h5 className="text-grey mb-2">Ideamarch Technologies Private Limited</h5>
                  <p><span className="fw-bold">Thamarai Enclave</span>,  Plot No: 34 & 35, Vijayaganapathy Nagar 3rd Street, Navallur, Chennai – 600 130. Phone: +91 7845 122 262</p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container-xl">
              <div className="text-center px-5 px-sm-0">Ideamarch Technologies Private Limited. © {year} . All Rights Reserved</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;