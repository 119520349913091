import Aboutus from './Aboutus';
import Intro from './Intro';
import Services from './Services';
import Products from './Products';

function Home() {
    return (
        <>
        <Intro/>
        <Aboutus/>
        <Services/>
        <Products />
        </>
    );

    
}

export default Home;