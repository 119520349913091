import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { HashLink } from "react-router-hash-link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
  <>
    <BrowserRouter>
      <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />
      <HashLink className="scroll-to-top hidden-mobile visible" to="#" aria-label="Scroll To Top"><i className='bx bx-chevron-up'></i></HashLink>
      <App>
      </App>
    </BrowserRouter>
  </>,
  document.getElementById("root")
);