import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Intro.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <>
            <div className="banner" id="home">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-8 col-md-7 col-sm-8">
                            <div className="banner-info">
                                <h1 data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1500">IDEAMARCH</h1>
                                <h6 data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="3000">We offers dynamic Web Applications, Mobile applications, and Analytical solutions developed with advanced technologies and frameworks to guarantee exponential requirements and standards. With our at most Research and Development process, we design a highly defined experience for our solutions.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner-bottom">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 212" preserveAspectRatio="none">
                    <polygon fill="#F3F3F3" points="0,75 479,161 1357,28 1701,56 1920,26 1920,213 0,212 "></polygon>
                    <polygon fill="#FFFFFF" points="0,91 481,177 1358,44 1702,72 1920,42 1920,212 0,212 "></polygon>
                </svg>
            </div>
            <section>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-xxl-4 col-lg-5 col-md-6">
                            <div className="home-msg" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500">
                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active" data-bs-interval="4000"> “An Idea with knowledge creates a plan” </div>
                                        <div className="carousel-item" data-bs-interval="4000"> “A Plan with Discipline makes Progress” </div>
                                        <div className="carousel-item" data-bs-interval="4000"> “A Progress with Passion solves Purpose” </div>
                                    </div>
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Home;