import React from "react";
import "./Aboutus.css"

function Aboutus() {
  return (
    <>
      <div className="about" id="about">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6">
              <div className="about-img" data-aos="zoom-in" data-aos-duration="1500">
                <img alt="" src="about-bg.jpg" /> </div>
            </div>
            <div className="col-md-6">
              <h4 className="page-title">About Us</h4>
              <p>Ideas without effort and effort without passion will never turn into reality. We realized that
                innovative ideas turn lifeless, without passionate effort. So with the ultimate intention of creating
                value, we planned to march the creative ideas of people with passionate minds and started our
                journey.</p>
              <p>Ideamarch Technologies Private Limited, a tech startup company based out of Chennai to connect
                the ideas of the world into reality through a team of industry experts and a holistic process. Our
                team promises to make your ideas technically possible with a precise design and a robust
                application to your business.</p>
              <p>To prove the trust in our solutions, we in Ideamarch own a unique business model to bill our clients
                with a “pay after use” approach to manifest the standard of our solutions.</p>
              <p>To make our user experience more likable, we design our services with the industry trending tools
                for an efficient rendering and flawless experience to stand out from the crowd.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Aboutus;
