import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import React, { useState, useRef } from "react";
import './Career.css'
import { Link } from "react-router-dom";

function Careers() {
  const MAX_FILE_SIZE = 5120;

  const [careerInfo, setcareerInfo] = useState({

    id: "",
    cname: "",
    mob_no: "",
    email: "",
    clg_name: "",
    qualification: "",
    location: "",
    job_id: "",
    message: "",
    tid: "1",
    linkedin: ""
  });

  const [recaptchaValue, setRecaptchaValue] = useState('');
  const captchaRef = useRef();
  const [resumeFile, setResumeFile] = useState();

  const [errorMessage, setErrorMessage] = useState({});
  const errMsg = {};

  const { cname, mob_no, email, clg_name, qualification, location, job_id, message, tid, linkedin } = careerInfo;

  const handleChange = (e) => {
    setcareerInfo({ ...careerInfo, [e.target.name]: e.target.value });
    setErrorMessage({ ...errorMessage, [e.target.name]: '' });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setResumeFile(e.target.files[0]);
    }
  };

  const validate = (careerInfo) => {
    const name_pattern = /^([a-zA-Z-. ])*$/;
    if (!careerInfo.cname.trim()) {
      errMsg.cname = 'Please enter Name';
      return false;
    } else if (name_pattern.test(careerInfo.cname) === false) {
      errMsg.cname = "Only alphabets - and . are allowed";
      return false;
    }
    else if (careerInfo.cname.length < 2) {
      errMsg.cname = 'Please enter minimum 2 characters';
      return false;
    } else if (careerInfo.cname.length > 50) {
      errMsg.cname = 'Name cannot exceed 50 characters';
      return false;
    }

    const mobno_pattern = /^([0-9])*$/;
    if (!careerInfo.mob_no.trim()) {
      errMsg.mob_no = 'Please enter Phone No';
      return false;
    } else if (careerInfo.mob_no.length !== 10) {
      errMsg.mob_no = "Please enter valid phone number";
      return false;
    }
    else if (mobno_pattern.test(careerInfo.mob_no) === false) {
      errMsg.mob_no = "Only numbers are allowed";
      return false;
    }

    // ^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4})*$
    const email_pattern = /^([a-zA-Z0-9.]+@[a-zA-Z0-9]+[.][a-zA-Z]{2,4})*$/
    if (!careerInfo.email.trim()) {
      errMsg.email = 'Please enter Email';
      return false;
    } else if (email_pattern.test(careerInfo.email) === false) {
      errMsg.email = "Please enter a valid Email ID ";
      return false;
    } else if (careerInfo.email.length > 100) {
      errMsg.email = 'Email cannot exceed 100 characters';
      return false;
    }

    if (!careerInfo.clg_name.trim()) {
      errMsg.clg_name = 'Please enter College Name';
      return false;
    } else if (careerInfo.clg_name.length > 100) {
      errMsg.clg_name = 'College Name cannot exceed 100 characters';
      return false;
    }

    if (!careerInfo.qualification.trim()) {
      errMsg.qualification = 'Please enter Qualification';
      return false;
    } else if (careerInfo.qualification.length > 50) {
      errMsg.qualification = 'Qualification cannot exceed 50 characters';
      return false;
    }

    if (!careerInfo.location.trim()) {
      errMsg.location = 'Please enter Location';
      return false;
    } else if (careerInfo.location.length > 100) {
      errMsg.location = 'Location cannot exceed 100 characters';
      return false;
    }

    // if (!careerInfo.job_id.trim()) {
    //   errMsg.job_id = 'Please enter Job ID';
    //   return false;
    // } else if (careerInfo.job_id.length > 15) {
    //   errMsg.job_id = 'Job ID cannot exceed 15 characters';
    //   return false;
    // }

    if (!careerInfo.message.trim()) {
      errMsg.message = 'Please enter Message';
      return false;
    } else if (careerInfo.message.length > 200) {
      errMsg.message = 'Message cannot exceed 200 characters';
      return false;
    }

    if (careerInfo.linkedin.trim()==="" || careerInfo.linkedin.trim() === "SELECT") {
      errMsg.linkedin = "Please provide your answer";
      return false;
    } else if (careerInfo.linkedin.trim()==="YES") {
      return true;
    }

    if (resumeFile) {
      if ((resumeFile.type.indexOf("pdf") < 0) && (resumeFile.type.indexOf("msword") < 0) &&
        (resumeFile.type.indexOf("openxmlformats") < 0)) {
        errMsg.file_name = "Please select PDF or Word documents alone";
        return false;
      } else if (resumeFile.size / 1024 > MAX_FILE_SIZE) {
        errMsg.file_name = "File size has to be less 3 MB";
        return false;
      }
    }
    else {
      errMsg.file_name = 'Please select file';
      return false;
    }

    return true;
  }

  // const onChange = value => {
  //   setRecaptchaValue(value);
  // }

  const onSubmit = async e => {
    e.preventDefault();

    //captchaRef.current.reset();

    if (validate(careerInfo) === true) {
      const formData = new FormData();
      formData.append('tid', tid);
      formData.append('name', cname);
      formData.append('mob_no', mob_no);
      formData.append('email', email);
      formData.append('clg_name', clg_name);
      formData.append('qualification', qualification);
      formData.append('location', location);
      formData.append('job_id', "JOBID");
      formData.append('message', message);
      formData.append('linkedin', linkedin);
      formData.append('file_name', resumeFile);

      //console.log(formData);
      axios.post(process.env.REACT_APP_API_HOST+"/careerideamarch", formData)
        .then(response => {
        });
      alert("Thank you for subming your resume, we will get in touch with you soon.");
      setcareerInfo({ ...careerInfo, 'cname': '', 'mob_no': '', 'email': '', 'clg_name': '', 'qualification': '', 'location': '', 'job_id': '', 'message': '', 'linkedin': '' });
      document.getElementById("file_name").value = "";
      setErrorMessage(errMsg);
    }
    else {
      setErrorMessage(errMsg);
    }
  }

  return (
    <>
      <section className="careers" id="careers">
        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="banner-info2">
                <h1>Career</h1>
                <h6>Unlock Your Potential: Join Our Team to Shape the Future of Ideamarch</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="rotate2-180">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
            <polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "></polygon>
            <polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "></polygon>
          </svg>
        </div>
      </section>
      <section class="careerform">
        <div className="container">
          <div className="row g-5 justify-content-center">
            
              <div className="col-lg-8">
              <form onSubmit={e => onSubmit(e)}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="uname">Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" name="cname" id="cname"
                      value={careerInfo.cname} onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.cname}</span>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="uphone">Phone Number<span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" name="mob_no" id="mob_no"
                      maxLength={10} value={careerInfo.mob_no} onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.mob_no}</span>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="uemail">Your Email <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" name="email" id="email"
                      value={careerInfo.email} onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.email}</span>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="uclg">College Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" name="clg_name" id="clg_name"
                      value={careerInfo.clg_name} maxLength="100" onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.clg_name}</span>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="uqualification">Qualification <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" name="qualification" id="qualification"
                      value={careerInfo.qualification} maxLength="50" onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.qualification}</span>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="ulocation">Location
                      <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control rounded-0" name="location" id="location"
                      value={careerInfo.location} maxLength="100" onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.location}</span>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="linkedin" >Are you following Ideamarch on LinkedIn?
                      <span className="text-danger">*</span>
                    </label>
                   
                    <select name="linkedin"  className="form-control rounded-0" value={careerInfo.linkedin} onChange={e => handleChange(e)} rows="2">
                      <option value='SELECT'>Select</option>
                      <option value='YES'>Yes</option>
                      <option value='NO'>No</option>
                    </select>
                    <Link to="https://in.linkedin.com/company/ideamarch" target="_blank"><i className='bx bxl-linkedin' ></i></Link>
                    <span className="text-danger">{errorMessage.linkedin}</span>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="uresume">Your Resume <small>(File Size: 2MB, File Type: pdf, docx)</small>
                      <span className="text-danger">*</span></label>
                    <div className="custom-file">
                      <input className="form-control rounded-0" type="file" name="file_name"
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e) => handleFileChange(e)} id="file_name" />
                      <span className="text-danger">{errorMessage.file_name}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="umsg">Your Message
                      <span className="text-danger">*</span>
                    </label>
                    <textarea className="form-control rounded-0" rows="2" name="message" id="message"
                      value={careerInfo.message} onChange={e => handleChange(e)}></textarea>
                    <span className="text-danger">{errorMessage.message}</span>
                  </div>
                  {/* <div className="col-md-6">
                    <label htmlFor="ujobid">Job ID
                      <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control rounded-0" name="job_id" id="job_id"
                      value={careerInfo.job_id} onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.job_id}</span>
                  </div> */}
                                    
                  {/* <div className="col-md-6">
                    <label htmlFor="uchaptcha">Please verify the chaptcha
                      <span className="text-danger">*</span>
                    </label>
                    <ReCAPTCHA sitekey="6LddpEwlAAAAAP-2e1O9iQjFqGRez6_Z83GZgZ6T" onChange={onChange} ref={captchaRef} />
                    {/* <input type="text" className="form-control rounded-0" /> }
                  </div> */}
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-blue rounded-0">Send</button>
                  </div>
                  <div className="col-12"> <small className="text-black-50"><span className="text-danger">*</span> submit your resume through the form above.</small> </div>
                </div>
                </form>
              </div>
           
          </div>
        </div>
      </section>
    </>
  );
}
export default Careers;