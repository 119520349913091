import React from "react";
import "./Services.css"

function Services() {
  return (
    <>
      <div className="services" id="services">
        <div className="container-xl mb-md-5">
          <div className="row mb-md-5">
            <div className="col-12 text-center">
              <h4 className="page-title center">Services</h4>
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="500">
                <h4>Product Development</h4>
                <h5>Spin up your Idea into an IT Product</h5>
                <p>We make your ideas live with a value-centric Product Lifecycle planned and executed by our Industry expert technical consulting team with an economical efficiency and constructive outcome.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1000">
                <h4>Web Application</h4>
                <h5>Productive Application for Business Operations</h5>
                <p>Business operations deserve a facilitative IT solution to ease your process. Help us with your business case to build an interactive and firm web application with a service-level guarantee.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="1500">
                <h4>Mobile Application</h4>
                <h5>Cross-Platform Mobile Applications</h5>
                <p> Pioneers in mobile application development, crafting innovative solutions that seamlessly merge functionality and user experience. From concept to execution, transform ideas into dynamic and user-friendly.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-right" data-aos-easing="linear" data-aos-duration="500">
                <h4>Cloud Application</h4>
                <h5>Instantly scalable application at a minimal downtime</h5>
                <p>Cloud applications are the modern evolution, from building a powerful business application to choosing a cost-efficient cloud service provider guided by Subject matter experts.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-left" data-aos-easing="linear" data-aos-duration="2000">
                <h4>Data Analytics </h4>
                <h5>Unlocking Insights, Powering Decisions</h5>
                <p>revolutionizes businesses through expert data analytics, transforming raw information into strategic insights that catalyze growth. Elevate your decision-making with Ideamarch's data-driven expertise</p>
              </div>
            </div>            
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-right" data-aos-easing="linear" data-aos-duration="1000">
                <h4>Web services</h4>
                <h5>Upgrade your System with Modern Architecture</h5>
                <p>Evolution never ends, develop your application with the latest architecture to optimize your application functionalities for future evolution.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-right" data-aos-easing="linear" data-aos-duration="1500">
                <h4>Product Integration</h4>
                <h5>Integrate your business systems into one solution</h5>
                <p>Integrate your vendor software to build a centralized application system for efficient Management.</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
              <div className="service-info" data-aos="flip-right" data-aos-easing="linear" data-aos-duration="2000">
                <h4>Website</h4>
                <h5>Creative Digital profile for your Business</h5>
                <p>Build an interactive and responsive face for your business’s Digital existence to promote your business to a vast audience with a Search engine optimized landing page.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;