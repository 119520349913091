import React, { useEffect } from "react";
import "./Products.css";
import AOS from 'aos';
import Slider from "react-slick";

function Products() {
  useEffect(() => {
    AOS.init();
  }, [])

  var settings = {
    // dots: true,
    infinite: true,
    // arrows: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 2,
      //   }
      // },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
      <section className="products" id="products">
        <div className="">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
            <polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "></polygon>
            <polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "></polygon>
          </svg>
        </div>
        <div className="container-xl mb-md-5">
          <div className="row mb-md-5">
            <div className="col-12 text-center">
              <h4 className="page-title center">Products</h4>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-3">
            </div>
            <div className="col-md-6">
              <div className="product-info" data-aos="flip-up" data-aos-easing="linear" data-aos-duration="1000">
                <img src="docberry.png" className="product-logo" alt="..." />
                <h4>&nbsp;</h4>
                <h5>Docberry is a patient management system to maintain personal and medical records of patients in a
                  centralized form at your Health facilities.</h5>
                <h6 className="mt-4">Computerized Records</h6>
                <p>Docberry stores all the Inpatient and outpatient customer records and holds the personal and medical
                  data associated with each patient.</p>
                <h6 className="mt-4">Efficient Schedule</h6>
                <p>Facilitating the patients with setting up appointments with the respective physician at their
                  convenient slots.</p>
                <h6 className="mt-4">Health Track</h6>
                <p>Monitor the progress of the patient’s health condition over the treatment and stats out the overall
                  health record of the patient.</p>
              </div>
            </div>
            {/* <div className="col-md-6">
                <div className="product-info" data-aos="flip-up" data-aos-easing="linear" data-aos-duration="1000">
                  <img src="docberry.png" className="product-logo" alt="..." />
                  <h4>Docberry</h4>
                  <h5>Docberry is a patient management system to maintain personal and medical records of patients in a
                    centralized form at your Health facilities.</h5>
                  <h6 className="mt-4">Computerized Records</h6>
                  <p>Docberry stores all the Inpatient and outpatient customer records and holds the personal and medical
                    data associated with each patient.</p>
                  <h6 className="mt-4">Efficient Schedule</h6>
                  <p>Facilitating the patients with setting up appointments with the respective physician at their
                    convenient slots.</p>
                  <h6 className="mt-4">Health Track</h6>
                  <p>Monitor the progress of the patient’s health condition over the treatment and stats out the overall
                    health record of the patient.</p>
                </div>
              </div> */}
          </div>
        </div>
        <div className="rotate-180">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
            <polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "></polygon>
            <polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "></polygon>
          </svg>
        </div>
      </section>
      <section className="client">
        <div className="container-xl mb-md-5">
          <div className="row mb-md-5">
            <div className="col-12 text-center">
              <h4 className="page-title center">Clients</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="client-carousel slick-initialized slick-slider">
                <Slider {...settings}>
                <div className="item">
                    <div className="client-img"> <img src="clients/arightco.png" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture1.png" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture2.png" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture3.png" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture4.png" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture5.png" alt="..." /> </div>
                  </div>

                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture6.jpg" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture7.jpg" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture8.jpg" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture9.png" alt="..." /> </div>
                  </div>
                  <div className="item">
                    <div className="client-img"> <img src="clients/Picture10.jpg" alt="..." /> </div>
                  </div>
                </Slider>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Products;