import React, { useState } from "react";
import axios from "axios";
import "./Contactus.css";

function Contactus() {
  const [contactInfo, setcontactInfo] = useState({
    id: "",
    cname: "",
    phone_no: "",
    email: "",
    subject: "",
    message: "",
    tid: "1"
  });

  const [errorMessage, setErrorMessage] = useState({});
  const errMsg = {};

  const handleChange = (e) => {
    setcontactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    setErrorMessage({ ...errorMessage, [e.target.name]: '' });
  }

  const validate = (contactInfo) => {
    const name_pattern = /^([a-zA-Z-. ])*$/;
    if (!contactInfo.cname.trim()) {
      errMsg.cname = 'Please enter Name';
      // setErrorMessage({ ...errorMessage, 'cname': 'Please enter Name'});
      return false;
    } else if (name_pattern.test(contactInfo.cname) === false) {
      errMsg.cname = "Only alphabets - and . are allowed";
      return false;
    }
    else if (contactInfo.cname.length < 2) {
      errMsg.cname = 'Please enter minimum 2 characters';
      return false;
    } else if (contactInfo.cname.length > 50) {
      errMsg.cname = 'Name cannot exceed 50 characters';
      return false;
    }

    const phoneno_pattern = /^([0-9])*$/;
    if (!contactInfo.phone_no.trim()) {
      errMsg.phone_no = 'Please enter Phone No';
      return false;
    } else if (contactInfo.phone_no.length !== 10) {
      errMsg.phone_no = "Please enter valid phone number";
      return false;
    }
    else if (phoneno_pattern.test(contactInfo.phone_no) === false) {
      errMsg.phone_no = "Only numbers are allowed";
      return false;
    }

    // ^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4})*$
    const email_pattern = /^([a-zA-Z0-9.]+@[a-zA-Z0-9]+[.][a-zA-Z]{2,4})*$/
    if (!contactInfo.email.trim()) {
      errMsg.email = 'Please enter Email';
      return false;
    } else if (email_pattern.test(contactInfo.email) === false) {
      errMsg.email = "Please enter a valid Email ID ";
      return false;
    } else if (contactInfo.email.length > 100) {
      errMsg.email = 'Email cannot exceed 100 characters';
      return false;
    }

    if (!contactInfo.subject.trim()) {
      errMsg.subject = 'Please enter Subject';
      return false;
    } else if (contactInfo.subject.length > 100) {
      errMsg.subject = 'Subject cannot exceed 100 characters';
      return false;
    }

    if (!contactInfo.message.trim()) {
      errMsg.message = 'Please enter Message';
      return false;
    } else if (contactInfo.message.length > 200) {
      errMsg.message = 'Message cannot exceed 200 characters';
      return false;
    }

    return true;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate(contactInfo) === true) {
      axios.post(process.env.REACT_APP_API_HOST+"/contactideamarch", contactInfo)
        .then(response => {
        })
      alert("Thank you for contacting us!");
      setcontactInfo({ ...contactInfo, 'cname': '', 'phone_no': '', 'email': '', 'subject': '', 'message': '' });
      setErrorMessage(errMsg);
    }
    else {
      console.log(errorMessage);
      setErrorMessage(errMsg);
    }
  }

  return (
    <>
        <div className="contact" id="contact">
          <div className="container-xl">
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="page-title center">Contact Us</h4>
              </div>
            </div>
            <div className="row gy-5 justify-content-center">
              <div className="col-md-4">
              <form onSubmit={e => onSubmit(e)}>
                <div className="row">
                <div className="col-12">
                  <div className="mb-2">
                    <label htmlFor="uname">Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" id="cname" name="cname" value={contactInfo.cname} onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.cname}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-2">
                    <label htmlFor="uphone">Phone <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" id="phone" name="phone_no" value={contactInfo.phone_no} onChange={e => handleChange(e)} maxLength="10" />
                    <span className="text-danger">{errorMessage.phone_no}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-2">
                    <label htmlFor="uemail">Email address <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" id="email" name="email" value={contactInfo.email} onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.email}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-2">
                    <label htmlFor="usubject">Subject <span className="text-danger">*</span></label>
                    <input type="text" className="form-control rounded-0" id="subject" name="subject" value={contactInfo.subject} onChange={e => handleChange(e)} />
                    <span className="text-danger">{errorMessage.subject}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-2">
                    <label htmlFor="umsg">Message <span className="text-danger">*</span></label>
                    <textarea className="form-control rounded-0" id="umsg" name="message" rows="3" value={contactInfo.message} onChange={e => handleChange(e)} ></textarea>
                    <span className="text-danger">{errorMessage.message}</span>
                  </div>
                </div>
                <div className="col-12">
                <button type="submit" className="btn btn-blue btn-sm rounded-0">Submit</button>
                </div>
                </div>
              </form>
              </div>
              
            </div>
          </div>
        </div>
    </>
  );
}

export default Contactus;